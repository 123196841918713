import { createRouter, createWebHistory } from 'vue-router'
import Acceuil from '@/components/HomeComponent.vue';
import NavBar from '@/components/NavbarComponent.vue';

const routes = [
  {
    path: '/',
    component: NavBar,
    redirect: { name: 'Acceuil' },
    children: [
      {
        name: 'Acceuil',
        path: '/',
        components: {
          default: NavBar,
          two: Acceuil
        }
      }
    ]
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
