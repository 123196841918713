<script>
window.onscroll = function () {
  scrollFunction();
};

function scrollFunction() {
  if (window.scrollY > 100) {
    document.querySelector("#nav").classList.add("bg-color-primary-dark/80");
    document.querySelector("#nav").classList.add("border-b");
    document.querySelector("#nav").classList.add("border-color-gray");
  } else {
    document.querySelector("#nav").classList.remove("bg-color-primary-dark");
    document.querySelector("#nav").classList.remove("border-b");
    document.querySelector("#nav").classList.remove("border-color-gray");
  }

  // active link
  let current = "home";
  const sectionn = document.querySelectorAll("section");
  const navlinkss = document.querySelectorAll("nav ul li a");
  sectionn.forEach((section) => {
    const sectionTop = section.offsetTop;
    if (pageYOffset >= sectionTop - 60) {
      current = section.getAttribute("id");
    }
  });
  navlinkss.forEach((item) => {
    item.classList.remove("text-color-secondary");
    if (item.href.includes(current)) {
      item.classList.add("text-color-secondary");
    }
  });
}

export default {
  data() {
    return {
      open: false
    }
  },
  methods: {
    MenuOpen() {
      this.open = !this.open
    },
    getImagePath(path) {
      // Utilisez cet appel pour obtenir le chemin correct
      return `https://infiniticgroup.com/` + path;
    }
  }
}
</script>
<template>
  <div>
    <div class="bg-color-primary text-color-white tracking-wider">
      <header id="nav" class="sticky top-0 z-50">
        <nav class=" lg:flex justify-between items-center px-6">
          <div class="py-5 text-color-secondary font-bold text-3xl">
            <a class="flex items-center cursor-pointer" href="#home">
              <img :src="getImagePath('pictures/logoInfinitic.png')" class="w-16 -rotate-12" alt="" srcset="" />
              <span class="text-color-white text-shadow-lg shadow-white">Infinitic</span>Group.
            </a>
          </div>
          <div>
            <ul
              class="lg:flex items-center justify-center lg:space-x-6 space-y-6 lg:space-y-0 lg:px-0 px-10 text-xl lg:static absolute bg-color-primary-dark/80 lg:bg-color-primary-dark/10 w-full top-[105px]"
              :class="[open ? 'left-0 duration-700 ease-in' : 'left-[-100%] duration-700 ease-in-out']">
              <li>
                <a href="#home"
                  class="hover:text-color-secondary text-shadow-lg hover:shadow-color-secondary ease-in duration-200 ">Accueil</a>
              </li>
              <li>
                <a href="#features"
                  class="hover:text-color-secondary text-shadow-lg hover:shadow-color-secondary ease-in duration-200">Nos
                  services</a>
              </li>
              <li>
                <a href="#propos"
                  class="hover:text-color-secondary text-shadow-lg hover:shadow-color-secondary ease-in duration-200">A
                  propos</a>
              </li>
              <li>
                <a href="#partenaires"
                  class="hover:text-color-secondary text-shadow-lg hover:shadow-color-secondary ease-in duration-200">Nos
                  partenaires</a>
              </li>
              <li>
                <a href="#equipe"
                  class="hover:text-color-secondary text-shadow-lg hover:shadow-color-secondary ease-in duration-200">Notre
                  équipe</a>
              </li>
              <li>
                <a href="#realisation"
                  class="hover:text-color-secondary text-shadow-lg hover:shadow-color-secondary ease-in duration-200">Nos
                  réalisations</a>
              </li>
              <li>
                <a href="#contact"
                  class="hover:text-color-secondary text-shadow-lg hover:shadow-color-secondary ease-in duration-200">Contact</a>
              </li>
            </ul>
          </div>

          <!-- Mobile screen  -->
          <div @click="MenuOpen()" id="hamburger"
            class="absolute lg:hidden cursor-pointer right-6 top-1.5 text-2xl z-50 flex items-center justify-center h-full">
            <i :class="[open ? 'fa-solid fa-times' : 'fa-solid fa-bars']"></i>
          </div>
        </nav>
      </header>
      <main>
        <router-view name="two" />
      </main>
    </div>
  </div>
</template>
