<template>
  <div>
    <main class="bg-color-primary text-color-white overflow-x-hidden tracking-wider">
      <section id="home" class="relative">
        <div class="">
          <div class="flex h-[50vh] md:h-screen items-center z-20">
            <div
              class="text-center h-full p-4 md:text-left z-20 bg-gradient-to-t to-black via-[#4e4d4d]/25 from-white/50 absolute">
              <div data-aos="zoom-in" data-aos-duration="3000"
                class="z-20 md:w-2/3 text-center mx-auto lg:h-full flex flex-col justify-center">
                <h1 class="text-2xl md:text-4xl font-bold leading-snug mb-4">Cessez de dépenser une fortune pour un
                  développeur ou technicien informatique!</h1>
                <p class="leading-relaxed mb-6 text-center">
                  <span class=" font-extrabold text-2xl">Infinitic<span class="text-color-secondary">Group.</span></span>
                  mets à votre disposition des équipes de développeurs pour la conception de vos applications, sites web
                  et
                  ou logiciels ainsi que des équipes de techniciens pour la réalisation et maintenance de vos
                  infrastructures informatiques.
                </p>
                <a href="#contact">
                  <button class="btn shadow-lg shadow-color-secondary">Contactez nous</button>
                </a>
              </div>
            </div>
            <div class="relative w-full h-[50vh] md:h-full">
              <img class="w-full h-full bg-cover" :src="getImagePath('pictures/equipesDev.jpg')" alt="pas d'images"
                srcset="">
            </div>
          </div>
        </div>
      </section>
      <section id="features" class="bg-color-primary-light">
        <div class="py-20">
          <div class="text-center mx-auto mb-20 md:w-2/3">
            <h4 class="font-bold text-color-secondary mb-4">Nos services</h4>
            <h1 data-aos="zoom-out" data-aos-duration="3000" class="text-2xl md:text-4xl font-bold leading-snug">Une
              gestion plus facile de votre entreprise ou projet grâçe à nos services</h1>
          </div>
          <div class="grid items-center lg:grid-cols-3 md:grid-cols-2 gap-12 lg:gap-8 px-4 sm:px-6 lg:px-8">
            <!-- card no 1 -->
            <div data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine"
              class="border-2 border-solid border-color-gray text-center shadow-lg shadow-color-secondary transform hover:-translate-y-10 hover:scale-500 py-10 px-5 rounded-2xl cursor-pointer hover:bg-color-primary-dark ease-in duration-[700ms]">
              <div class="bg-color-secondary inline-block rounded-2xl py-5 px-6">
                <i class="fa-solid fa-object-group text-4xl"></i>
              </div>
              <h3 class="text-2xl text-color-white text-shadow-sm shadow-white font-bold py-4">Développement</h3>
              <p class="leading-relaxed text-color-secondary">
                Mettre sur pieds des applications, sites ou logiciels dans les plus brefs délais et à moindre coût. Suivis
                ou maintenance après livraison des projets.
              </p>
            </div>
            <!-- card no 2 -->
            <div data-aos="zoom-in-up" data-aos-offset="300" data-aos-easing="ease-in-sine"
              class="bg-color-primary-dark border-2 border-solid border-color-gray text-center shadow-lg shadow-color-secondary transform hover:-translate-y-10 hover:scale-500 duration-[700ms] py-10 px-5 rounded-2xl cursor-pointer">
              <div class="bg-color-secondary inline-block rounded-2xl py-5 px-6">
                <i class="fa-solid fa-chart-column text-4xl"></i>
              </div>
              <h3 class="text-2xl text-color-white text-shadow-sm shadow-white font-bold py-4">consultation en
                technologies de l'information</h3>
              <p class="leading-relaxed text-color-secondary">
                Nous fournissons des conseils stratégiques en matière de technologie de l'information pour aider une
                entreprise à aligner ses objectifs commerciaux avec ses solutions technologiques. Cela peut inclure
                l'analyse des besoins, la planification de projet, la sélection de la meilleure technologie, etc.
              </p>
            </div>
            <!-- card no 3 -->
            <div data-aos="fade-left" data-aos-offset="300" data-aos-easing="ease-in-sine"
              class="border-2 border-solid border-color-gray text-center shadow-lg shadow-color-secondary transform hover:-translate-y-10 hover:scale-500 duration-[700ms] py-10 px-5 rounded-2xl cursor-pointer hover:bg-color-primary-dark ease-in">
              <div class="bg-color-secondary inline-block rounded-2xl py-5 px-6">
                <i class="fa-solid fa-graduation-cap text-4xl"></i>
              </div>
              <h3 class="text-2xl text-color-white text-shadow-sm shadow-white font-bold py-4">Développement des
                compétences</h3>
              <p class="leading-relaxed text-color-secondary">
                Nous proposons également des programmes de formation pour améliorer les compétences et les connaissances
                des individus en informatique.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section id="propos">
        <div class=" py-20 relative">

          <div class="flex flex-col items-center justify-between md:flex-row">
            <div class="mb-12 md:w-1/2">
              <img data-aos="zoom-out-right" data-aos-duration="3000" class=" animate-[bounce_8s_infinite]"
                :src="getImagePath('pictures/imagegroup.svg')" alt="" />
            </div>
            <div data-aos="zoom-out-left" data-aos-duration="3000" class="text-center md:w-1/2 p-4">
              <h4 class="font-bold text-color-secondary mb-4">
                A propos de nous
              </h4>
              <h1 class="text-2xl md:text-4xl font-bold leading-snug mb-4 text-shadow-lg shadow-color-secondary">
                Les meilleures solutions pour économiser de l'argent
              </h1>
              <p v-if="!readMoreActivated" v-html="longText.slice(0, 908)" class="leading-relaxed mb-10 text-left">

              </p>
              <button v-if="!readMoreActivated" @click="activateReadMore" class="btn shadow-lg shadow-color-secondary">En
                savoir plus...</button>
              <p class="leading-relaxed" v-if="readMoreActivated" v-html="longText"></p>
            </div>
          </div>
        </div>
      </section>
      <section id="partenaires" class="bg-color-primary-light">
        <div class=" py-20">
          <div class="text-center mx-auto mb-20 md:w-2/3">
            <h4 class="font-bold text-color-secondary mb-4">Nos partenaires</h4>
            <h1 class="text-2xl md:text-4xl font-bold leading-snug">Ensemble, nous assurons votre structure sur le plan
              informatique</h1>
          </div>
          <div class="flex items-center justify-center space-y-8 w-full mx-auto">
            <!-- card 1  -->
            <div data-aos="zoom-out-right" data-aos-duration="3000" class="text-center cursor-pointer">
              <div class="relative inline-block cursor-pointer ease-in duration-200">
                <img class=" h-16 " :src="getImagePath('pictures/weblease.png')" alt="azerty" srcset="">
              </div>
              <h3 class="text-2xl font-bold py-4 text-shadow-lg shadow-color-secondary">weblease</h3>
              <p class="leading-relaxed px-2">
                Weblease est une entreprise spécialisée dans la location de sites web. Fondée dans le but de résoudre le
                problème de présence professionnelle en ligne des entreprises.
              </p>
            </div>
            <!-- card 2  -->
            <!-- <div data-aos="zoom-out-left" data-aos-duration="3000" class="text-center cursor-pointer">
                <div
                  class="relative inline-block cursor-pointer ease-in duration-200"
                >
                    <img class=" h-16 " src="/weblease.png" alt="azerty" srcset="">
                </div>
                <h3 class="text-2xl font-bold py-4 text-shadow-lg shadow-color-secondary">AVWG</h3>
                <p class="leading-relaxed">
                  We use an application designed a testing gnose to keep away
                </p>
              </div> -->
            <!-- card 3  -->
            <!-- <div class="text-center cursor-pointer">
                <div
                  class="relative bg-color-primary-dark shadow-lg shadow-color-secondary inline-block px-6 py-3 rounded-lg cursor-pointer hover:bg-color-secondary ease-in duration-200"
                >
                  <p class="text-6xl">3</p>
                </div>
                <h3 class="text-xl font-bold py-4">Enjoy The Features!</h3>
                <p class="leading-relaxed">
                  We use an application designed a testing gnose to keep away
                </p>
              </div> -->
          </div>
        </div>
      </section>
      <section id="equipe">
        <div class="py-20">
          <div class="text-center m-auto mb-20 md:w-2/3">
            <h4 class="font-bold text-color-secondary mb-4">Notre équipe</h4>
            <h1 class="text-2xl md:text-4xl font-bold leading-snug">Chargée de la gestion de vos besoins et projets</h1>
          </div>
          <!-- review container  -->
          <div class="mt-8">
            <div class="flex items-center justify-center flex-wrap">
              <img :src="getImagePath('pictures/personnels/willie.jpeg')" alt=""
                class="h-20 w-20 rounded-full p-1 m-3 cursor-pointer object-center user-pic active-pic"
                v-on:click="showReview($event)" />
              <img :src="getImagePath('pictures/personnel.jpg')" alt=""
                class="h-20 w-20 rounded-full p-1 m-3 object-cover cursor-pointer user-pic"
                v-on:click="showReview($event)" />
              <img :src="getImagePath('pictures/personnels/rostant.jpg')" alt=""
                class="h-20 w-20 rounded-full p-1 m-3 object-cover cursor-pointer user-pic"
                v-on:click="showReview($event)" />
              <img :src="getImagePath('pictures/personnels/philippe.jpeg')" alt=""
                class="h-20 w-20 rounded-full p-1 m-3 object-cover cursor-pointer user-pic"
                v-on:click="showReview($event)" />
              <img :src="getImagePath('pictures/personnel.jpg')" alt=""
                class="h-20 w-20 rounded-full p-1 m-3 object-cover cursor-pointer user-pic"
                v-on:click="showReview($event)" />
            </div>
            <div class="grid place-items-center text-center mx-auto px-2 md:w-4/5 min-h-[40vh]">
              <div class="user-text active-text">
                <p class="md:text-2xl mb-6">
                  "Responsable de la planification, de l'organisation, de la coordination et de la gestion globale de vos
                  projets, du début à la fin. S'assurer que les objectifs du projet sont atteints dans les délais, le
                  budget et les ressources prévus."!
                </p>
                <h4 class="font-bold text-color-secondary mb-1">
                  TAGNE KENGNE WILLIE
                </h4>
                <p>Project manager</p>
              </div>
              <div class="user-text">
                <p class="md:text-2xl mb-6">
                  "Spécialisé dans la création d'applications mobiles pour différents systèmes d'exploitation tels que iOS
                  (pour les appareils Apple) et Android (pour les appareils basés sur Google)."!
                </p>
                <h4 class="font-bold text-color-secondary mb-1">
                  TAGNE CYRILLE
                </h4>
                <p>Développeur Flutter</p>
              </div>
              <div class="user-text">
                <p class="md:text-2xl mb-6">
                  "Spécialisé dans la conception, le développement et la maintenance de sites web. Responsable de la
                  création de l'architecture et de la structure du site, du codage des fonctionnalités et des
                  interactions, ainsi que de l'optimisation du site pour améliorer son accessibilité et sa performance."!
                </p>
                <h4 class="font-bold text-color-secondary mb-1">
                  Kamela Sokoudjou Rostant
                </h4>
                <p>Développeur laravel, nodejs</p>
              </div>
              <div class="user-text">
                <p class="md:text-2xl mb-6">
                  "Membre capable de concevoir et de développer des applications web complètes en utilisant un large
                  éventail de compétences techniques, ce qui en fait un atout précieux pour vos projets nécessitant une
                  expertise web complète."!
                </p>
                <h4 class="font-bold text-color-secondary mb-1">Bondima Philippe</h4>
                <p>Développeur Fullstack</p>
              </div>
              <div class="user-text">
                <p class="md:text-2xl mb-6">
                  "Membre capable de concevoir des interfaces pour applications web complètes en utilisant multiples
                  technologies, ce qui en fait un atout pour vos visuels."!
                </p>
                <h4 class="font-bold text-color-secondary mb-1">Houafo Jean</h4>
                <p>Développeur Frontend</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="realisation">
        <div class="px-4 py-20 bg-color-primary-light">
          <div class="text-center m-auto mb-20 md:w-1/2">
            <h4 class="font-bold text-color-secondary mb-4">
              Nos réalisations
            </h4>
            <h1 class="title">Quelques solutions mises sur pieds par nos équipes</h1>
          </div>
          <div class="lg:flex space-x-2">
            <!-- card no 1 -->
            <div class="lg:w-1/2">
              <div class="lg:h-[40vh] lg:w-[550px] mx-auto rounded-md scale-100 overflow-hidden">
                <swiper :effect="'cards'" :grabCursor="true" :modules="modules" class="mySwiper">
                  <swiper-slide></swiper-slide>
                  <swiper-slide></swiper-slide>
                  <swiper-slide></swiper-slide>
                  <swiper-slide></swiper-slide>
                  <swiper-slide></swiper-slide>
                  <swiper-slide></swiper-slide>
                </swiper>
              </div>

              <div class="p-2 py-4 text-center">

                <a href="#blog"
                  class="text-2xl font-bold underline hover:text-color-secondary hover:no-underline">Bestlive</a>

                <p class="leading-relaxed my-5 mx-auto">
                  <span class="text-2xl font-bold text-[#DE3450] text-shadow-lg shadow-[#DE3450]">BestLive</span> est un
                  site web de présentation des services et applications de la start Up.
                </p>
              </div>
            </div>
            <!-- card no 2 -->
            <div class="lg:w-1/2">
              <div class="lg:h-[40vh] lg:w-[550px] mx-auto rounded-md scale-100 overflow-hidden">
                <video autoplay loop controls class="w-full h-full" preload="auto">
                  <source :src="getImagePath('pictures/autoSend.webm')" type="video/webm">
                </video>
              </div>

              <div class=" text-center">

                <a href="#blog"
                  class="text-2xl font-bold underline hover:text-color-secondary hover:no-underline">AutoSend</a>

                <p class="leading-relaxed my-5 mx-auto">
                  <span class="text-2xl font-bold text-[#188C3E] text-shadow-lg shadow-[#188C3E]">AutoSend</span> est un
                  bot Whats'app d'envoi de messages automatiques! Elle offre la possibilité d'atteindre votre public cible
                  de manière efficace et personnalisée. Projet sur le quel nous étions chargés de la conception du
                  frontend.
                </p>
              </div>
            </div>
            <!-- card no 3 -->
            <!-- <div>
                <div class="lg:h-[40vh] rounded-xl scale-100 overflow-hidden">
                  <img
                    src="/img/blog3.jpg"
                    alt=""
                    class="lg:h-full w-full hover:scale-125 transition duration-300 ease-in-out"
                  />
                </div>
                <div>
                  <div class="flex items-center gap-5 py-5">
                    <p>20 August 2022</p>
                    <p>1 min read</p>
                  </div>
                  <a
                    href="#blog"
                    class="text-2xl font-bold underline hover:text-color-secondary hover:no-underline"
                    >Should Small Businesses Be Entitled to system?</a
                  >
                  <p class="leading-relaxed my-5">
                    Our team provides skilled & experienced managers who know
                    the intricacies of this vertical and focus...
                  </p>
                  <a
                    href="#blog"
                    class="inline-block font-bold hover:text-color-secondary transition-all duration-300 ease-in-out"
                  >
                    <span
                      class="tracking-wider capitalize underline hover:no-underline"
                      >Read more</span
                    >
                  </a>
                </div>
              </div> -->
          </div>
        </div>
      </section>
      <!-- <section id="download-app" class="bg-color-primary-light">
          <div class="container h-[80vh] grid place-items-center">
            <div class="text-center md:w-2/3 m-auto">
              <h1 class="title">Download App Now And Save Your Money</h1>
              <p class="leading-relaxed pt-5">
                Serving an impressive list of long-term money with experience
                and expertise in multiple industries.
              </p>
              <div
                class="flex flex-col md:flex-row items-center justify-center gap-5 pt-10"
              >
                <a
                  href="#"
                  class="bg-color-white h-16 w-44 grid place-items-center rounded-lg hover:opacity-70"
                >
                  <img src="/img/ios-store-dark.png" alt="" />
                </a>
                <a
                  href="#"
                  class="bg-color-white h-16 w-44 grid place-items-center rounded-lg hover:opacity-70"
                >
                  <img src="/img/g-play-dark.png" alt="" />
                </a>
              </div>
            </div>
          </div>
        </section> -->
      <section id="contact">
        <div class="container py-20">
          <div class="text-center m-auto mb-20 md:w-1/2">
            <h4 class="font-bold text-color-secondary mb-4">
              Nous contacter
            </h4>
            <h1 class="title">Passez a l'action</h1>
          </div>
          <form data-aos="fade-up" data-aos-anchor-placement="center-bottom" data-aos-duration="3000"
            @submit.prevent="sendEmail()">
            <div class="w-full m-auto text-center md:w-2/3">
              <div class="text-color-primary-dark grid gap-6 mb-6 md:grid-cols-2">
                <input v-model="form.user_name" type="text" required
                  class="bg-gray-50 border border-gray-300 text-sm rounded-lg block w-full p-3 focus:outline-none focus:border-color-secondary"
                  placeholder="Name" />
                <input v-model="form.user_email" type="email" required
                  class="bg-gray-50 border border-gray-300 text-sm rounded-lg block w-full p-3 focus:outline-none focus:border-color-secondary"
                  placeholder="Email" />
                <input v-model="form.user_phone" type="tel" required
                  class="bg-gray-50 border border-gray-300 text-sm rounded-lg block w-full p-3 focus:outline-none focus:border-color-secondary"
                  placeholder="Phone" />
                <input v-model="form.user_company" type="text"
                  class="bg-gray-50 border border-gray-300 text-sm rounded-lg block w-full p-3 focus:outline-none focus:border-color-secondary"
                  placeholder="Company" />
              </div>
              <textarea v-model="form.user_message" rows="4" required
                class="text-color-primary-dark bg-gray-50 border border-gray-300 text-sm rounded-lg block w-full p-3 focus:outline-none focus:border-color-secondary"
                placeholder="Message"></textarea>
              <button class="btn mt-10 shadow-lg shadow-color-secondary">Envoyer Message</button>
            </div>
          </form>
        </div>
      </section>
      <section id="footer">
        <div class="bg-color-primary-dark relative">
          <div class="container py-10">
            <div class="grid gap-10 md:grid-cols-3 pb-10">
              <div class="space-y-6">
                <h4 class="font-bold text-lg">A Propos</h4>
                <p class="leading-relaxed">
                  Grâce à notre expertise technique et notre expérience approfondie, nous sommes en mesure de concevoir
                  des solutions innovantes et performantes, adaptées aux exigences de chaque projet.
                </p>
                <div class="flex gap-5 items-center">
                  <p>Suivez Nous</p>
                  <i class="fa-brands fa-facebook-f cursor-pointer"></i>
                  <a href="https://wa.me/237698318808">
                    <i class="fa-brands fa-whatsapp cursor-pointer">
                    </i>
                  </a>

                  <a href="https://www.instagram.com/infiniticgroup?igsh=ZWc2M2R3eHFqdzc2">
                    <i class="fa-brands fa-instagram cursor-pointer"></i>
                  </a>
                </div>
              </div>
              <div class="flex justify-between md:justify-around">
                <div class="space-y-6">
                  <h4 class="font-bold text-lg">Quick Links</h4>
                  <ul class="space-y-3">
                    <li class="underline hover:no-underline hover:text-color-secondary">
                      <a href="#home">Accueil</a>
                    </li>
                    <li class="underline hover:no-underline hover:text-color-secondary">
                      <a href="#features">Nos services</a>
                    </li>
                    <li class="underline hover:no-underline hover:text-color-secondary">
                      <a href="#propos">A propos</a>
                    </li>
                    <li class="underline hover:no-underline hover:text-color-secondary">
                      <a href="#partenaires">Nos Partenaires</a>
                    </li>
                    <li class="underline hover:no-underline hover:text-color-secondary">
                      <a href="#equipe">Notre équipe</a>
                    </li>
                    <li class="underline hover:no-underline hover:text-color-secondary">
                      <a href="#realisation">Nos réalisations</a>
                    </li>
                    <li class="underline hover:no-underline hover:text-color-secondary">
                      <a href="#contact">Contact</a>
                    </li>
                  </ul>
                </div>
                <div class="space-y-6">
                  <h4 class="font-bold text-lg">Aide</h4>
                  <ul class="space-y-3">
                    <li class="underline hover:no-underline hover:text-color-secondary">
                      <a href="#">À propos de nous</a>
                    </li>
                    <li class="underline hover:no-underline hover:text-color-secondary">
                      <a href="#">Partenaires</a>
                    </li>
                    <li class="underline hover:no-underline hover:text-color-secondary">
                      <a href="#">Carrière</a>
                    </li>
                    <li class="underline hover:no-underline hover:text-color-secondary">
                      <a href="#">Commentaires</a>
                    </li>
                    <li class="underline hover:no-underline hover:text-color-secondary">
                      <a href="#">Aide</a>
                    </li>
                  </ul>
                </div>
              </div>
              <!-- <div class="space-y-6">
                  <h4 class="font-bold text-lg">Newsletter</h4>
                  <p class="leading-relaxed">
                    Subscribe With Email And Loads Of News Will Be Sent To You
                  </p>
                  <div class="flex items-center">
                    <input
                      type="text"
                      class="w-3/4 text-color-gray bg-color-white p-2 lg:p-3 rounded-l-md focus:outline-none"
                      placeholder="Enter Your Email"
                    />

                    <button
                      type="submit"
                      class="bg-color-secondary px-4 py-2 lg:px-5 lg:py-3 rounded-r-md hover:opacity-90"
                    >
                      <i class="fa-solid fa-chevron-right"></i>
                    </button>
                  </div>
                </div> -->
            </div>
            <div class="flex justify-center pt-10 border-t border-color-gray">
              <p>2023 &copy; InfiniticGroup. Tous droits réservés.</p>
            </div>
          </div>
        </div>
      </section>
    </main>
  </div>
</template>
<script>
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'swiper/vue';

// Import Swiper styles
import 'swiper/css';

import 'swiper/css/effect-cards';

// import required modules
import { EffectCards } from 'swiper/modules';

// testimonial
const userTexts = document.getElementsByClassName("user-text");
const userPics = document.getElementsByClassName("user-pic");

//imports
import emailjs from "emailjs-com";

export default {
  components: {
    Swiper,
    SwiperSlide,
  },

  data() {
    return {
      form: {
        user_name: "",
        user_email: "",
        user_message: "",
        user_company: "",
        user_phone: "",
      },
      modules: [EffectCards],
      longText: `Je vous présente <span class=" font-extrabold text-2xl">Infinitic<span class="text-color-secondary">Group.</span></span>, notre nouvelle entreprise spécialisée dans le secteur informatique. Nous offrons une gamme complète de services axés sur le développement d'applications et de logiciels, la consultation en technologie informatique et la formation.
          Notre équipe d'experts en développement est capable de concevoir et de créer des applications personnalisées répondant aux besoins spécifiques de nos clients. Que ce soit pour une application mobile, une application web ou une application de bureau, nous travaillons en étroite collaboration avec nos clients pour garantir des solutions efficaces et innovantes.
          De plus, notre service de consultation en technologie informatique offre des conseils et des solutions stratégiques pour aider les entreprises à optimiser leur infrastructure informatique. Nous analysons les besoins et les objectifs de nos clients, puis nous leur fournissons des recommandations personnalisées pour améliorer leur efficacité opérationnelle, leur sécurité et leur évolutivité.
          Enfin, notre programme de formation est conçu pour offrir des cours et des ateliers pratiques qui permettent aux professionnels de se former aux dernières technologies et aux meilleures pratiques du secteur informatique. Que ce soit pour le développement de logiciels, la gestion de projet ou la sécurité informatique, notre équipe de formateurs expérimentés est là pour accompagner nos clients dans leur montée en compétences.
          Chez <span class=" font-extrabold text-2xl">Infinitic<span class="text-color-secondary">Group.</span></span>, notre objectif est de fournir des solutions informatiques de haute qualité et d'aider nos clients à tirer le meilleur parti de la technologie pour soutenir leur croissance et leur succès dans un environnement numérique en constante évolution.`,
      readMoreActivated: false
    };
  },
  methods: {
    showReview(event) {
      for (let userPic of userPics) {
        userPic.classList.remove("active-pic");
      }
      for (let userText of userTexts) {
        userText.classList.remove("active-text");
      }
      let i = Array.from(userPics).indexOf(event.target);
      userPics[i].classList.add("active-pic");
      userTexts[i].classList.add("active-text");
    },
    sendEmail() {
      let params = {
        from_name: this.form.user_name,
        company: this.form.user_company,
        email_id: this.form.user_email,
        phone: this.form.user_phone,
        message: this.form.user_message,
      };
      emailjs
        .send(
          "service_v6lm0ce",
          "template_y89ga0h",
          params,
          "oRPjCo_0ggqPNUG8e"
        )
        .then(
          (result) => {
            this.form.user_company = null;
            this.form.user_email = null;
            this.form.user_name = null;
            this.form.user_phone = null;
            this.form.user_message = null;
            console.log(
              "You have successfully submitted your message",
              result.text
            );
          },
          (error) => {
            console.log(
              "This form failed to submit, please kindly check your internet connection",
              error.text
            );
          }
        );
    },
    activateReadMore() {
      this.readMoreActivated = true;
    },
    getImagePath(path) {
      // Utilisez cet appel pour obtenir le chemin correct
      return `https://infiniticgroup.com/` + path;
    }
  },
};
</script>
<style>
.swiper {
  width: 340px;
  height: 220px;
}

@media (min-width: 1024px) {
  .swiper {
    width: 550px;
    height: 330px;
  }

  .swiper-slide {
    width: 100%;
    height: 100%;
  }

  .swiper-slide:nth-child(1n) {
    background-image: url('../../public/pictures/zen/bestlive1.png');
    background-size: cover;
    background-repeat: no-repeat;

  }

  .swiper-slide:nth-child(2n) {
    background-image: url('../../public/pictures/zen/bestlive2.png');
    background-size: cover;
    background-repeat: no-repeat;
  }

  .swiper-slide:nth-child(3n) {
    background-image: url('../../public/pictures/zen/bestlive3.png');
    background-size: cover;
    background-repeat: no-repeat;

  }

  .swiper-slide:nth-child(4n) {
    background-image: url('../../public/pictures/zen/bestlive4.png');
    background-size: cover;
    background-repeat: no-repeat;

  }

  .swiper-slide:nth-child(5n) {
    background-image: url('../../public/pictures/zen/bestlive5.png');
    background-size: cover;
    background-repeat: no-repeat;

  }

  .swiper-slide:nth-child(6n) {
    background-image: url('../../public/pictures/zen/bestlive6.png');
    background-size: cover;
    background-repeat: no-repeat;

  }
}

.swiper-slide {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 18px;
  font-size: 22px;
  font-weight: bold;
  color: #fff;
}

.swiper-slide:nth-child(1n) {
  background-image: url('../../public/pictures/zen/bestlive1.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.swiper-slide:nth-child(2n) {
  background-image: url('../../public/pictures/zen/bestlive2.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.swiper-slide:nth-child(3n) {
  background-image: url('../../public/pictures/zen/bestlive3.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.swiper-slide:nth-child(4n) {
  background-image: url('../../public/pictures/zen/bestlive4.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.swiper-slide:nth-child(5n) {
  background-image: url('../../public/pictures/zen/bestlive5.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.swiper-slide:nth-child(6n) {
  background-image: url('../../public/pictures/zen/bestlive6.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
</style>